import React from 'react';
// import tw from 'twin.macro'; // eslint-disable-line import/no-extraneous-dependencies

import PageWrapper from '../components/PageWrapper';

const AboutPage = () => {
  return (
    <PageWrapper>
      <div css={[]}>about</div>
    </PageWrapper>
  );
};

export default AboutPage;
